import React, { Component } from 'react';
import { Placeholder, withSitecoreContext, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import {withSitecoreRouter} from 'utils/withRouter';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';
import Tabs from './components/Tabs';
import qs from 'query-string';
import { serializeTags } from 'utils/serialize';
import { filterquerymodifier, tagsquerymodifier } from 'utils/filterquerymodifier';
import ErrorUtility from 'utils/errorutility';
import {
    INFORMATION_IDENTIFIER,
    EXHIBITORS_IDENTIFIER,
    ARTICLES_IDENTIFIER,
    LINES_IDENTIFIER,
    EVENTS_IDENTIFIER,
    PRODUCTS_IDENTIFIER,
    SEARCH_IDENTIFIER,
    EXHIBIT_IDENTIFIER,
    DIRECTORY_IDENTIFIER,
    CATALOGS_IDENTIFIER,
    SPECIALS_IDENTIFIER,
} from 'utils/exhibitor';
import ImcDataLayer from 'utils/datalayer';
import { SEARCH_TYPE_EXHIBITOR, SEARCH_TYPE_PRODUCT, getFilterKeyFromPath, sanatizeSearchQuery } from 'utils/search'
import SearchPubSubService from "modules/search/services/pubsub";

import exhibitorSpecialsReducer from '../Digital-Showroom-Specials/reducers/exhibitorSpecialsReducer';
import mock from './mock.json';
import { getDictionaryValue, SEARCH_RESULTS_DICTIONARY_KEY_PREFIX, } from 'utils/dictionary';
import { Loading } from 'modules/loading';
import { getParamsFromShowroomUrl } from 'utils/exhibitor';
import { getSearchTypeFromPath } from 'utils/search';
import UserUtility from '../../utils/userutility';
import { cloneDeep } from 'lodash';
import ExhibitorsAPI from '../../api/exhibitor.js';
import { resetPagination } from '../../utils/resetpagination';
import Link from '../Link';

const DEFAULT_PAGESIZE = 10;
const _exhibitorAPI = new ExhibitorsAPI();



class DigitalTabbedNavigationComponent extends Component {
    /**
     * @method constructor
     * @description On instantiation dynamically sets the moduleName based on the `mediaGalleryId` and
     * creates aliases to methods
     * @param {object} props Incoming props
   */
    constructor(props) {
        super(props);
        const { sitecoreContext } = this.props;
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
            this.exhibitId = sitecoreContext.exhibitor.id;
        }
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
            this.lineId = sitecoreContext.lineProduct.lineId;
        }

        this.state = {
            currentIndex: null,
            prodCount: 0,
            catCount: 0,
            lineCount: 0,
            informationCount: 0,
            articlesCount: 0,
            eventsCount: 0,
            specialsCount: 0,
            products: {},
            catalogs: {},
            lines: {},
            information: {},
            filters: {},
            exhibitors: {},
            exhibitorsCount: 0,
            currentPage: 1,
            tab: 'exhibitor',
            exhibitId: this.exhibitId,
            lineId: this.lineId,
            filterTerms: {},
            term: '',
            showLoading: false
        };
        this.setActiveItem = this.setActiveItem.bind(this);
        this.setHistory = this.setHistory.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.getActiveDigitalTabComponent = this.getActiveDigitalTabComponent.bind(this);
        this.searchResultsChange = this.searchResultsChange.bind(this);
        this.efficientlyGetLineCountViaSearch = this.efficientlyGetLineCountViaSearch.bind(this);
    }

    /**
     * @method setActiveItem
     * @description Sets the state with the current index
     * @param {Number} index The index number of the carousel item to select
     * @param {object=} e Event object
    */
    setActiveItem(index, tab, e) {
        const code = e ? e.keyCode || e.which : undefined;
        if (code === undefined || code === 13 || code === 32) {
            this.setState({
                currentIndex: index,
                tab: tab,
            });
        }
    }


    /**
     * @method setHistory
     * @description Push to history object
     */
    setHistory(path) {
        this.props.router.navigate(path);
    }

    /**
* Updates state with new props
* @param {object} nextProps Incoming props
*/
    componentDidUpdate(prevProps) {
        const router = this.props.router;
        let urlData = getParamsFromShowroomUrl(router.location.pathname);

        if ((prevProps.router.location.search !== router.location.search) || (prevProps.router.location.pathname !== router.location.pathname)
        ) {
            const qsParsed = qs.parse(router.location.search, { ignoreQueryPrefix: true });
            const qsLastParsed = qs.parse(prevProps.router.location.search, { ignoreQueryPrefix: true });
            const lastTerm = qsLastParsed.q || '';
            const term = qsParsed.q || '';
            let alphabetName = '';
            if (term === lastTerm) {
                alphabetName = qsParsed.alphabetName || '';
            } else {
                delete qsParsed['alphabetName'];
            }

            const type = qsParsed.type || '';
            var filterType = getFilterKeyFromPath(router.location.search);
            if (typeof filterType === 'undefined') //FIx to handle directory type pages
                filterType = router.location.pathname.split('/').pop();
            const filters = qs.parse(qsParsed[filterType] || '');
            let sort = qsParsed.sortorder || '';
            let pageNum = 0
            if (prevProps.router.location.pathname !== router.location.pathname) {
                pageNum = 1
                const { match } = this.props;
                delete qsParsed['page'];
                //default sort orders
                if (router.location.pathname.indexOf('articles') > -1 || router.location.pathname.indexOf('information') > -1)
                    qsParsed.sortorder = 'desc';
                const newHistory = resetPagination(match, router.location);
                newHistory.search = qs.stringify(qsParsed);
                newHistory.pathname = router.location.pathname;
                console.log(newHistory.pathname);
                newHistory.pagenum = pageNum;
            } else {
                pageNum = qsParsed.page || '1';
                if (term !== lastTerm) {
                    const { match, history } = this.props;
                    delete qsParsed['page'];
                    const newHistory = resetPagination(match, this.props.router.location);
                    newHistory.search = qs.stringify(qsParsed);
                    newHistory.pathname = router.location.pathname;
                    console.log(newHistory.pathname);
                    newHistory.pagenum = pageNum;
                }
            }

            const page = pageNum;
            this.setActiveTab();
            this.setState({
                currentPage: page,
                term: term,
                alphabetName: alphabetName,
                type: type,
                exhibitId: urlData.exhibitId,
                lineId: urlData.lineId

            });
            this.fetchData(term, alphabetName, type, filters, sort, router.location.pathname, page);
        }
    }

    componentDidMount() {
        const qsParsed = qs.parse(this.props.router.location.search, { ignoreQueryPrefix: true });
        const term = qsParsed.q || '';
        const alphabetName = qsParsed.alphabetName || '';
        const type = qsParsed.type || '';
        const sort = qsParsed.sortorder || '';
        const page = qsParsed.page || '1';
        var filterType = getFilterKeyFromPath(this.props.router.location.search);
        if (typeof filterType === 'undefined') //FIx to handle directory type pages
            filterType = this.props.router.location.pathname.split('/').pop();
        const filters = qs.parse(qsParsed[filterType] || '');
        this.setActiveTab();
        this.fetchData(term, alphabetName, type, filters, sort, this.props.router.location.pathname, page);
        this.setState({
            filterTerms: filters,
            term: term,
        })
        this.searchPubSubService = SearchPubSubService.getInstance();
    }

    setActiveTab() {
        const activeTab = this.getActiveDigitalTabComponent();
        if (activeTab) {
            this.setActiveItem(activeTab.dataSource, activeTab.fields.type.value.toLowerCase());
        }

    }
    isActiveTab(path, type, tab_type) {
        return typeof path !== 'undefined' && path.toLowerCase().includes(tab_type) && type.toLowerCase() === tab_type;
    }


    getCountSelectedFilters(filters) {
        let filtersCount = 0;
        Object.keys(filters).forEach((key) => {
            const decoded = decodeURI(filters[key]);
            filtersCount += decoded.indexOf('|') > -1 ? decoded.split('|').length : (decoded !== '' ? 1 : 0);

        });
        return filtersCount;
    }
    getProductsCount(data) {
        let counter = 0;
        data && data.forEach(element => {
            counter += (Array.isArray(element.products) ? element.products.length : 0);
        });
        return counter;
    }

    // Calls line search with small page size to get count
    efficientlyGetLineCountViaSearch(urlData, apikeyParam, searchPageParam, querystringLines) {
        fetch(`${getSitecoreApiHost()}/imc-api/v2/lines/search?exhibitorId=${urlData.exhibitId}&${apikeyParam}&${querystringLines}&${searchPageParam}&pageSize=1`
            , {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': UserUtility.getChannelData().name,
                }
            })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    lineCount: data.count,
                })
            }).catch(err => {
                this.setState({ showLoading: false });
            })
    }
    getActiveDigitalTabComponent() {

        const digitalTabs = this.props.rendering.placeholders["imc-digital-tabbed-navigation-content"].filter((item) => item.componentName === 'Digital-Tab');
        if (Array.isArray(digitalTabs)) {
            const tab = digitalTabs.find((digitalTab) => {
                return (digitalTab && 'fields' in digitalTab && 'type' in digitalTab.fields) &&
                    (this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, EXHIBITORS_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, PRODUCTS_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, INFORMATION_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, LINES_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, ARTICLES_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, CATALOGS_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, EVENTS_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, DIRECTORY_IDENTIFIER)
                        || this.isActiveTab(this.props.router.location.pathname, digitalTab.fields.type.value, SPECIALS_IDENTIFIER)
                    )
            });
            return tab;
        }
        return null
    }
    getDictionaryMessage(type, countMatchingResults, countType, countFilter, term) {
        let message = '';
        let fixedTerm = term.trim();
        let predicate = `${fixedTerm.length>0 || countFilter > 0 ? getDictionaryValue('for', 'for'):""} ${countFilter > 0 ? `${getDictionaryValue('forXFilter', ` ${countFilter} Filter`, { countFilter })}`:""} ${ fixedTerm.length>0 && countFilter>0 ? getDictionaryValue('and', 'and'):""} ${term}`;
        switch (type) {
            case EXHIBITORS_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')}, ${countType} Products ${predicate}`;
                break;
            case PRODUCTS_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')} ${predicate}`;
                break;
            case INFORMATION_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')} ${predicate}`;
                break;
            case LINES_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')}, ${countType} Products ${predicate}`;
                break;
            case ARTICLES_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')} ${predicate}`;
                break;
            case CATALOGS_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')} ${predicate}`;
                break;
            case EVENTS_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')} ${predicate}`;
                break;
            case SPECIALS_IDENTIFIER:
                message = `${countMatchingResults} ${getDictionaryValue('matchingResults', 'Matching Results')}`;
                break;
        }
        return message.replace(`and  ""`, ``).replace(`for  ""`, ``);
    }
    searchResultsChange(type, countMatchingResults, countType, countFilter, term) {
        if (typeof countMatchingResults === 'undefined')
            countMatchingResults = 0;
        const messageChanged = {
            type: 'alert',
            message: getDictionaryValue(SEARCH_RESULTS_DICTIONARY_KEY_PREFIX + type,
                this.getDictionaryMessage(type, countMatchingResults, countType, countFilter, term),
                {
                    countMatchingResults,
                    countType,
                    countFilter,
                    term
                }),
        };

        this.searchPubSubService.emitMessageChanged(messageChanged);
        const resultsCountChanged = { type: type, count: countMatchingResults };
        this.searchPubSubService.emitMatchedResultsCountChanged(resultsCountChanged);
        this.setState({ showLoading: false });
    }




    fetchData(term, alphabetName, type, filters, sort, path, page) {

        let exhibitId;
        const { sitecoreContext } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        this.setState({ showLoading: true });
        const apiKey = getSitecoreApiKey();
        let urlData = getParamsFromShowroomUrl(this.props.router.location.pathname);
        term = sanatizeSearchQuery(term);
        
        //fetch exhibitors
        ///imc-api/v1/search/searchExhibitors?term=term&type=product&alphabetName=A&sc_apikey={your_apikey}
        let params = {
            'sc_apikey': apiKey,
        }
        let paramsProducts = {
            'sc_apikey': apiKey,
        }
        let paramsLines = {
            'sc_apikey': apiKey,
        }
        let paramsArticlesEventsAndInformation = {
            'sc_apikey': apiKey,
        }
        let paramsEvents = {
            'sc_apikey': apiKey,
        }
        if (term !== '') {
            params.term = term;
            paramsProducts.term = term;
            paramsLines.term = term;
            paramsArticlesEventsAndInformation.term = term;
            paramsEvents.term = term;
        }
        if (alphabetName !== '') {
            params.alphabeticalName = alphabetName;
            paramsProducts.alphabetName = alphabetName;
        }
        if (typeof page !== 'undefined') {
            paramsProducts.page = page;
            paramsLines.page = page;
            paramsArticlesEventsAndInformation.page = page;
            paramsEvents.page = page;
        }
        else {
            paramsProducts.page = 1;
            paramsLines.page = 1;
            paramsArticlesEventsAndInformation.page = 1;
            paramsEvents.page = 1;
        }
        if (type !== '') {
            switch (type) {
                case SEARCH_TYPE_EXHIBITOR:
                    params.type = 'exhibitorline';
                    break;
                case SEARCH_TYPE_PRODUCT:
                    params.type = 'product';
                    break;
            };
        }
        if (filters !== '') {
            params.f = filters;
            paramsProducts.f = filters;
            paramsLines.f = filters;
            paramsArticlesEventsAndInformation.tags = filters;
            paramsEvents.f = filters;
        }
        if (sort !== '') {
            if (path.includes(EXHIBITORS_IDENTIFIER) || path.includes(EVENTS_IDENTIFIER) || path.includes(DIRECTORY_IDENTIFIER)) {
                params.sortOrder = sort;
            } else {
                params.sortBy = sort;
            }
            paramsProducts.sortOrder = sort;
            paramsLines.sortOrder = sort;
            paramsArticlesEventsAndInformation.sortOrder = sort;
            paramsEvents.sortOrder = sort;
        }

        const placeholder = this.getActiveDigitalTabComponent();

        params.page = page;
        params.pageSize = !!placeholder && 'fields' in placeholder && 'size' in placeholder.fields ? placeholder.fields.size.value : DEFAULT_PAGESIZE;
        paramsProducts.pageSize = params.pageSize;
        paramsLines.pageSize = params.pageSize;
        paramsArticlesEventsAndInformation.pageSize = params.pageSize;
        paramsEvents.pageSize = params.pageSize;

        let searchPageParam = `&searchPage=${sitecoreContext.route.itemId}`;
        let apikeyParam = `&sc_apiKey=${apiKey}`;

        let querystring = serializeTags(params);
        querystring = filterquerymodifier(querystring);
        let querystringProducts = serializeTags(paramsProducts);
        querystringProducts = filterquerymodifier(querystringProducts);
        let querystringLines = serializeTags(paramsLines);
        querystringLines = filterquerymodifier(querystringLines);

        let querystringArticlesEventsAndInformation = serializeTags(paramsArticlesEventsAndInformation);
        querystringArticlesEventsAndInformation = filterquerymodifier(tagsquerymodifier(querystringArticlesEventsAndInformation));

        let querystringEvents = serializeTags(paramsEvents);
        querystringEvents = filterquerymodifier(querystringLines);

        if (this.props.fields.type.value == '' || this.props.fields.type.value == EXHIBIT_IDENTIFIER) {
            /****************************************************************************************************
             *                                  DIGITAL SHOWROOM PAGE FETCH DATA
             *****************************************************************************************************/
            //Get counts if showroom page

            if (urlData.lineId != null) {
                fetch(`${getSitecoreApiHost()}/imc-api/v2/search/lineCount?lineId=${urlData.lineId}${searchPageParam}${apikeyParam}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'channel': UserUtility.getChannelData().name,
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            prodCount: data.products,
                            catCount: data.catalogs,

                        })
                        this.setCountState(data, path);
                    }).catch(err => {
                        this.setState({ showLoading: false });
                    })
            }
            else {
                fetch(`${getSitecoreApiHost()}/imc-api/v2/search/exhibitorCount?exhibitorId=${urlData.exhibitId}${searchPageParam}${apikeyParam}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'channel': UserUtility.getChannelData().name,
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            prodCount: data.products,
                            catCount: data.catalogs,
                            specialsCount: data.showSpecials,
                            eventsCount: data.events
                        })
                        this.setCountState(data, path);
                    }).catch(err => {
                        this.setState({ showLoading: false });
                    })
                this.efficientlyGetLineCountViaSearch(urlData, apikeyParam, searchPageParam, querystringLines);
            }
            // fetch products
            if (!isEditing && path.includes(PRODUCTS_IDENTIFIER)) {
                if (urlData.lineId != null) {
                    fetch(`${getSitecoreApiHost()}/imc-api/v2/products/lineProducts?manufactureId=${urlData.lineId}&${querystringProducts}${searchPageParam}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                products: data.data,
                                prodCount: data.count,
                                showLoading: false
                            })
                            if (path.includes(PRODUCTS_IDENTIFIER))
                                this.searchResultsChange(PRODUCTS_IDENTIFIER, data.count, data.count, this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.showroomLineProductsSearch', 'An error ocurred while performing the products search.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            });
                        });
                }
                else {

                    fetch(`${getSitecoreApiHost()}/imc-api/v2/products/search?exhibitorId=${urlData.exhibitId}&${querystringProducts}${searchPageParam}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                products: data.data,
                                prodCount: data.count,
                                showLoading: false
                            })
                            if (path.includes(PRODUCTS_IDENTIFIER))
                                this.searchResultsChange(PRODUCTS_IDENTIFIER, data.count, data.count, this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.showroomProductsSearch', 'An error ocurred while performing the products search.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            });
                        });
                }

            } else {
                this.setState({
                    products: mock.products.products,
                    prodCount: mock.products.count,
                })
            }
            if (!isEditing) {
                if (urlData.lineId != null) {
                    //Commented out,  there is no add cataglog to line functionality build in Portal
                    /*fetch(`${getSitecoreApiHost()}/imc-api/v2/catalogs/lineCatalogs?lineId=${urlData.lineId}&${querystringProducts}${searchPageParam}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }})
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                catalogs: data.data,
                                catCount: data.count,
                                showLoading:false
                            })
                            if(path.includes(CATALOGS_IDENTIFIER))
                                this.searchResultsChange(CATALOGS_IDENTIFIER, data.count, data.count, this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.showroomLineCatalogsSearch', 'An error ocurred while performing the catalogs search.'),'ERROR');
                            this.setState({
                                showLoading:false
                            });});
                        */
                }
                else {
                    fetch(`${getSitecoreApiHost()}/imc-api/v2/catalogs/search?ExhibitorId=${urlData.exhibitId}&sc_apikey=${getSitecoreApiKey()}&${querystringProducts}${searchPageParam}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                catalogs: data.data,
                                catCount: data.count,
                                showLoading: false
                            })
                            if (path.includes(CATALOGS_IDENTIFIER))
                                this.searchResultsChange(CATALOGS_IDENTIFIER, data.count, data.count, this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.showroomCatalogsSearch', 'An error ocurred while performing the catalogs search.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            });
                        });
                }
            } else {
                this.setState({
                    catalogs: mock.catalogs.catalogs,
                    catCount: mock.catalogs.count,
                    showLoading: false
                })
            }
            if (!isEditing && path.includes(SPECIALS_IDENTIFIER)) {
                _exhibitorAPI.getExhibitorSpecials(this.exhibitId).then((response) => {
                    const count = response.length > 0 ? response[0].list?.length : 0;
                    this.setState({
                        specialsCount: count,
                        showLoading: false
                    })
                    this.searchResultsChange(SPECIALS_IDENTIFIER, count, count, this.getCountSelectedFilters(filters), term);
                }).catch(err => {
                    ErrorUtility.logError(err, getDictionaryValue('error.showroomSpecialsSearch', 'An error ocurred while retrieving the show specials information.'), 'ERROR');
                    this.setState({
                        showLoading: false
                    })
                })
            }
            if (!isEditing && path.includes(EVENTS_IDENTIFIER)) {

                fetch(`${getSitecoreApiHost()}/imc-api/v2/events/search?${querystringEvents}&exhibitorId=${urlData.exhibitId}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Channel': UserUtility.getChannelData().name,
                    }
                }).then(response => response.json()).then(data=> {

                    this.setState({
                        events: data.data,
                        eventsCount: data.count,
                        showLoading: false
                    })
                    this.searchResultsChange(EVENTS_IDENTIFIER, data.count, data.count, this.getCountSelectedFilters(filters), term);
                }).catch(err => {
                    ErrorUtility.logError(err, getDictionaryValue('error.showroomEventsSearch', 'An error ocurred while retrieving the events information.'), 'ERROR');
                    this.setState({
                        showLoading: false
                    })
                })
            }



            if (!isEditing) {
                if (!!!urlData.lineId && path.includes(LINES_IDENTIFIER)) {
                    fetch(`${getSitecoreApiHost()}/imc-api/v2/lines/search?exhibitorId=${urlData.exhibitId}&sc_apikey=${getSitecoreApiKey()}&${querystringLines}${searchPageParam}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                lines: data.data,
                                lineCount: data.count,
                                showLoading: false
                            })
                            if (path.includes(LINES_IDENTIFIER))
                                this.searchResultsChange(LINES_IDENTIFIER, data.count, data.productsCount, this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.showroomLinesSearch', 'An error ocurred while retrieving the lines information.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            });
                        });
                    this.setState({ exhibitId: this.exhibitId, });
                }
            } else {
                this.setState({
                    lines: mock.lines.lines,
                    lineCount: mock.lines.count,
                    showLoading: false
                })
            }


        } else if (this.props.fields.type.value == '' || this.props.fields.type.value == SEARCH_IDENTIFIER) {

            /****************************************************************************************************
             *                                  SEARCH PAGE FETCH DATA
             *****************************************************************************************************/
            if (!('term' in params) && !('f' in params && Object.keys(params.f).length > 0)) {
                this.setState({
                    exhibitors: null,
                    exhibitorsCount: 0,
                    showLoading: false
                });
                const searchType = getSearchTypeFromPath(this.props.router.location.pathname);
                const messageChanged = {
                    type: 'info',
                    message: getDictionaryValue(`${SEARCH_RESULTS_DICTIONARY_KEY_PREFIX}default_${searchType}`, 'Search & Discover from 4,000+ Lines and 1,000,000+ Products'),
                };
                SearchPubSubService.getInstance().emitMessageChanged(messageChanged);
            }
            fetch(`${getSitecoreApiHost()}/imc-api/v2/search/count?${querystring}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'channel': UserUtility.getChannelData().name,
                }
            })
                .then(response => response.json())
                .then(data => {
                    this.setCountState(data, path);
                }).catch(err => {
                    this.setState({ showLoading: false });
                })

            if ((path.includes(EXHIBITORS_IDENTIFIER) || path.includes(DIRECTORY_IDENTIFIER)) && ('term' in params || ('f' in params && Object.keys(params.f).length > 0))) {
                //TODO:REFACTOR. All these api call here should really be in the appropiate api folder source file!!!
                fetch(`${getSitecoreApiHost()}/imc-api/v2/exhibitors/search?${querystring}${searchPageParam}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Channel': UserUtility.getChannelData().name,
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.setState({
                            exhibitors: data.data,
                            exhibitorsCount: data.count,
                            showLoading: false
                        })
                        this.searchResultsChange(EXHIBITORS_IDENTIFIER, data.count, data.totalMatchedProduct ? data.totalMatchedProduct : 0, this.getCountSelectedFilters(filters), term);
                    }).catch(err => {
                        ErrorUtility.logError(err, getDictionaryValue('error.exhibitorsSearch', 'An error ocurred while performing the exhibitors search.'), 'ERROR');
                        this.setState({
                            showLoading: false
                        })
                    })
            } else if (path.includes(INFORMATION_IDENTIFIER)) {
                if (!isEditing) {
                    fetch(`${getSitecoreApiHost()}/imc-api/v1/information/search?${querystringArticlesEventsAndInformation}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                information: data.data,
                                informationCount: data.count,
                                showLoading: false
                            })
                            this.searchResultsChange(INFORMATION_IDENTIFIER, data.count, this.getProductsCount(data.data), this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.informationSearch', 'An error ocurred while retrieving the information results.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            })
                        })
                } else {
                    this.setState({
                        showLoading: false
                    })
                }
            } else if (path.includes(ARTICLES_IDENTIFIER)) {
                if (!isEditing) {
                    fetch(`${getSitecoreApiHost()}/imc-api/v1/articles/search?${querystringArticlesEventsAndInformation}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                articles: data.data,
                                articlesCount: data.count,
                                showLoading: false
                            })
                            this.searchResultsChange(ARTICLES_IDENTIFIER, data.count, this.getProductsCount(data.data), this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.articlesSearch', 'An error ocurred while retrieving the article results.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            })
                        })
                } else {
                    this.setState({
                        showLoading: false
                    })
                }
            }
            else if (path.includes(EVENTS_IDENTIFIER)) {
                if (!isEditing) {
                    fetch(`${getSitecoreApiHost()}/imc-api/v1/events/search?${querystringEvents}`, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            'Channel': UserUtility.getChannelData().name,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                events: data.data,
                                eventsCount: data.count,
                                showLoading: false
                            })
                            this.searchResultsChange(EVENTS_IDENTIFIER, data.count, this.getProductsCount(data.data), this.getCountSelectedFilters(filters), term);
                        }).catch(err => {
                            ErrorUtility.logError(err, getDictionaryValue('error.eventsSearch', 'An error ocurred while searching for events.'), 'ERROR');
                            this.setState({
                                showLoading: false
                            })
                        })
                } else {
                    this.setState({
                        events: mock.events.events,
                        eventsCount: mock.events.count,
                        showLoading: false
                    })
                }
            }

        }
    }

    ///Given the count endpoint returns
    setCountState(data, path) {
        if (data.exhibitors == 0 &&
            data.events == 0 &&
            data.articles == 0 &&
            data.information == 0 &&
            data.specials == 0 &&
            data.lines == 0
        ) {
            ImcDataLayer.pushInteractionEvent("search", "no results", this.state.term);
        };

        if (!path.includes(EXHIBITORS_IDENTIFIER) && !path.includes(DIRECTORY_IDENTIFIER))
            this.setState({ exhibitorsCount: data.exhibitors });
        if (!path.includes(INFORMATION_IDENTIFIER))
            this.setState({ informationCount: data.information });
        if (!path.includes(ARTICLES_IDENTIFIER))
            this.setState({ articlesCount: data.articles });
        if (!path.includes(EVENTS_IDENTIFIER))
            this.setState({ eventsCount: data.events });
        if (!path.includes(LINES_IDENTIFIER))
            this.setState({ linesCount: data.lines });
        if (!path.includes(SPECIALS_IDENTIFIER))
            this.setState({ specialsCount: data.length || data.showSpecials });
        if (!path.includes(PRODUCTS_IDENTIFIER))
            this.setState({ products: data.length });



    }

    render() {
        const path = this.props.router.location.pathname;
        if (typeof this.props.fields === 'undefined') {
            if (isEditorActive()) {
                return <h1 className="alarm">Datasource isn't set.</h1>;
            }
            return (
                <h1 className="alarm">
                    Data is not provided. Contact administrators, please.
                </h1>
            );
        }
        const digitalTabItems = this.props.rendering.placeholders["imc-digital-tabbed-navigation-content"].filter((item) => item.componentName === 'Digital-Tab' && item.dataSource !== '');
        return (
            <div className="">
                <div className="imc-tabs-nav__container imc-tabs-nav__container__digital imc-section imc-tabs__tabLinkContainer">
                    {digitalTabItems.length > 0 &&
                        <Tabs tabData={this.state} tabsCount={digitalTabItems.length} searchType={this.props.fields.type.value} path={path} setHistory={this.setHistory} setActive={this.setActiveItem} {...this.props} exhibitorID={this.exhibitId} key={this.props.fields.type.value} lineID={this.lineId} />
                    }
                    {this.props.fields.AZURL && this.props.fields.AZTitle && (
                        <Link className='imc-content--beta imc-tabs-nav-digital imc-tabs__azDirectoryLink' href={this.props.fields.AZURL.value}>{this.props.fields.AZTitle.value}</Link>
                    )}
                </div>
                <Loading showLoading={this.state.showLoading} showPageLoading={false} />
                <Placeholder tabData={this.state} defaultPageSize={DEFAULT_PAGESIZE} name='imc-digital-tabbed-navigation-content' rendering={this.props.rendering} currentIndex={this.state.currentIndex} />
            </div>
        )
    }
}

export default withSitecoreContext()(
    withSitecoreRouter((DigitalTabbedNavigationComponent))
);
