import React from 'react';
import ReactModal from 'react-modal';
import { createAppState } from 'modules/redux-app-state';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import {withSitecoreRouter} from 'utils/withRouter';
import { bindActionCreators } from 'redux';
import { getDictionaryValue } from 'utils/dictionary';
import ErrorUtility from 'utils/errorutility';

import * as dataActions from './actions/dataActions';
import profileReducer from './reducers/reducer';
import i18next from 'i18next';
import Link from '../Link';

import { Form, CheckboxesGroup, TextField, SubmitButton, DropDown } from 'modules/formelements';
import { Loading } from 'modules/loading';
import GenericModal from '../../common-components/GenericModal/GenericModal';
import { ReactComponent as InfoIcon } from '../../assets/svg/common/RedAlertIcon.svg';

const theState = createAppState();

const deleteModalContentDefault = (
  <>
    <p>We are sorry to see you go.</p>
    <br />
    <p>Are you sure you want to delete your account?</p>
    <br />
    <p>Deleting your account will remove all of your information from your database. There will be no way to restore your account.</p>
  </>
);

const deleteModalContentWithAssociations = (
  <>
    <p>We are sorry to see you go.</p>
    <br />
    <p>Are you sure you want to delete your account?</p>
    <br />
    <p>Deleting your account will remove all of your information from your database. There will be no way to restore your account.</p>
    <br />
    <span className='imc-profile--container'>
      <span className='imc-profile--icon-container'>
        <InfoIcon className='imc-profile--icon' />
      </span>
      <strong>
        <p>In addition, your exhibitor portal account will be deleted. This removes access to exhibitors.</p>
      </strong>
    </span>
  </>
);

class ProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    theState.reducerRegistry.register({ profileReducer });

    this.state = {
      isOpenRegistrationPopup: false,
      isOpenLinkExhibitorPopup: false,
      isOpenAddNewEmail: false,
      isOpenDeleteEmailConfirm: false,
      isOpenSetAsUsernameConfirm: false,
      isOpenResetPassword: false,
      isAccountDeletionModalOpen: false,
      isAccountDeletionAssociationModalOpen: false,
      isAccountDeletionConfirmationModalOpen: false,
      selectedFutureMarkets: "",
      registeredEmailAddress: "",
      selectedExhibitorName: "",
      newEmailAddress: "",
      selectedEmailToDelete: "",
      zipCode: "",
      selectedEmailToSetAsUsername: "",
      editName: false,
      editZipCode: false,
      editCompanyName: false,
      showLoading: true
    };

    this.changeAttendeeType = this.changeAttendeeType.bind(this);
    this.changeCompanyType = this.changeCompanyType.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);

    this.openRegistrationPopup = this.openRegistrationPopup.bind(this);
    this.openLinkExhibitorPopup = this.openLinkExhibitorPopup.bind(this);
    this.openAddEmailPopup = this.openAddEmailPopup.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.setAsUsernameSelectedEmail = this.setAsUsernameSelectedEmail.bind(this);
    this.deleteSelectedEmail = this.deleteSelectedEmail.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateZipCode = this.updateZipCode.bind(this);
    this.updateCompanyName = this.updateCompanyName.bind(this);
    this.getDataForCheckboxesGroup = this.getDataForCheckboxesGroup.bind(this);

    this.updateProductInterestsValue = this.updateProductInterestsValue.bind(this);
    this.resetPassword = this.resetPassword.bind(this);

    this.submitNewRegistration = this.submitNewRegistration.bind(this);
    this.submitNewAssociation = this.submitNewAssociation.bind(this);

    this.changeFutureMarket = this.changeFutureMarket.bind(this);
    this.isDemandDriver = this.isDemandDriver.bind(this);

    this.toggleAccountDeletionModal = this.toggleAccountDeletionModal.bind(this);
    this.toggleDeleteConfirmationModal = this.toggleDeleteConfirmationModal.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
  }

  componentDidMount() {
    //this.props.dataActions.fetchMockProfile();
    if (!isEditorActive()) {
      this.props.dataActions.fetchProfile();
      this.props.dataActions.getAllAttendeeTypes();
      this.props.dataActions.getAllCompanyTypes();
      this.props.dataActions.getAllProductInterest();
      this.props.dataActions.getNextMarketRegisterNowButton();
      this.props.dataActions.getFutureMarketLinkRegistrationButton();
    } else {
      this.props.dataActions.fetchMockProfile();
    }
  }

  toggleAccountDeletionModal(isOpen) {
    const { profile } = this.props;

    if (profile.revenueGenerators?.length > 0) {
      this.setState({ isAccountDeletionAssociationModalOpen: isOpen })
    } else {
      this.setState({ isAccountDeletionModalOpen: isOpen })
    }
  }

  toggleDeleteConfirmationModal(isOpen) {
    this.setState({
      isAccountDeletionConfirmationModalOpen: isOpen,
      isAccountDeletionModalOpen: false,
      isAccountDeletionAssociationModalOpen: false,
    })
  }

  deleteAccount() {
    this.props.dataActions.deleteAccount();
    this.toggleDeleteConfirmationModal(false);
  }

  renderEmails() {
    const { profile } = this.props;
    if (profile != null && profile.additionalEmails != null) {

      return (profile.additionalEmails.map((mail, index) => {
        let mailClass = "imc-type--title-7";
        if (index == 0) {
          mailClass += " imc-type--bold";
        }
        let mailDisplay = (
          <div key={index}>
            <div className="email-column">{mail}</div>
            <span>
              <span onClick={e => this.openDeleteEmailConfirm(mail)}>
                <svg width="16" height="16">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconTrashDelete" />
                </svg>
              </span>
              <a className="imc-type--title-7-link imc-content--margin-left-xlarge imc-content--pointer" onClick={e => this.openSetAsUsernameConfirm(mail)}>{getDictionaryValue('setAsUsername', 'Set As Username')}</a>
            </span>
          </div>
        );
        return (mailDisplay);
      }));
    }
  }

  setAsUsernameSelectedEmail() {
    this.props.dataActions.setEmailAsDefault(this.state.selectedEmailToSetAsUsername)
    this.handleClosePopup();
  }

  deleteSelectedEmail() {
    this.props.dataActions.deleteEmail(this.state.selectedEmailToDelete);
    this.handleClosePopup();
  }

  changeAttendeeType(event) {
    this.props.dataActions.updateAttendeeType(event.target.key, event.target.value);
  }

  changeCompanyType(event) {
    this.props.dataActions.updateCompanyTypes(event.target.key, event.target.value);
  }

  updateProductInterestsValue(updatedName, updatedVal) {
    const { productInterest, profile } = this.props;
    var updatedProductInterests = [];
    if (profile != null && profile.categories != null) {
      updatedProductInterests = profile.categories;
    }
    if (updatedVal) {
      for (let i = 0; i < productInterest.categories?.length; i++) {
        const categoryElement = productInterest.categories[i];
        if (categoryElement.key == updatedName) {
          updatedProductInterests.push(categoryElement);
        }
      }
    } else {
      for (let i = 0; i < updatedProductInterests?.length; i++) {
        const categoryElement = updatedProductInterests[i];
        if (categoryElement.key == updatedName) {
          updatedProductInterests.splice(i, 1);
        }
      }
    }

    this.props.dataActions.updateProductInterests(updatedProductInterests);
  }

  isDemandDriver() {
    const { profile } = this.props;
    return profile.demandDriver != null;
  }

  renderProductInterest() {
    const { productInterest } = this.props;
    if (productInterest != null) {
      return (<CheckboxesGroup
        wrapperClass="checkboxes"
        name="productInterest"
        data={this.getDataForCheckboxesGroup()}
        updateFieldValue={this.updateProductInterestsValue}
        labelKey="key"
        valueKey="value"></CheckboxesGroup>);
    } else {
      return null;
    }
  }

  getDataForCheckboxesGroup() {
    const { productInterest, profile } = this.props;
    var checkboxData = [];
    if (profile.digitalIdentity != null && profile.digitalIdentity.categories != null) {
      for (let i = 0; i < productInterest.categories?.length; i++) {
        const element = productInterest.categories[i];
        var checked = false;
        for (let i = 0; i < profile.digitalIdentity.categories?.length; i++) {
          const categoryElement = profile.digitalIdentity.categories[i];
          if (categoryElement.key == element.key) {
            checked = true;
          }
        }

        checkboxData.push({
          ...element,
          checked: checked
        });
      }
    }

    return checkboxData;
  }

  renderCompanyTypeOptions() {
    const { companyTypes } = this.props;
    if (companyTypes != null) {
      return (<React.Fragment>
        {companyTypes.map((item, index) => (
          <option key={index} value={item.key}>{item.value}</option>
        ))}
      </React.Fragment>);
    } else {
      return null;
    }
  }

  renderAttendeeOptions() {
    const { attendeeTypes } = this.props;
    if (attendeeTypes != null) {
      return (<React.Fragment>
        {attendeeTypes.map((item, index) => (
          <option key={index} value={item.key}>{item.value}</option>
        ))}
      </React.Fragment>);
    } else {
      return null;
    }
  }

  updateCompanyName(form) {
    this.props.dataActions.updateCompanyName(form.companyName);
    this.deactivateEditCompanyName();
  }

  deactivateEditCompanyName() {
    this.setState({ editCompanyName: false });
  }

  activateEditCompanyName() {
    this.setState({ editCompanyName: true });
  }

  renderCompanyName() {
    const { profile } = this.props;
    let companyName = profile.companyName;
    if (companyName == null) {
      companyName = "";
    }
    if (this.state.editCompanyName) {
      return (
        <Form
          actionUrl=""
          messageError=""
          customSubmit={this.updateCompanyName} >
          <div className="inline-form">
            <TextField type="text" name="companyName" label={getDictionaryValue('companyName', 'Company Name')} labelClass="imc-type--title-7 imc-type--bold" value={companyName} inputCls="imc-profile--input"></TextField>
            <button onClick={e => this.deactivateEditCompanyName()} className="imc-button imc-button--accent1-inverted imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">{getDictionaryValue('discard', 'Discard')}</button>
            <SubmitButton
              labelSubmit={getDictionaryValue('save', 'Save')}
              additionalClass="imc-button imc-button--secondary imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">
            </SubmitButton>
          </div>
        </Form>
      );
    } else {
      return (
        <div className="imc-formfield imc-content " data-xpath="form.textfieldContainer">
          <div className="imc-vr--xsmall "><label className="imc-type--title-7 imc-type--bold">{getDictionaryValue('companyName', 'Company Name')}</label></div>
          <p className='imc-type--title-7'>
            {profile.companyName}
            {
              (!this.isDemandDriver()) ?
                <span onClick={e => this.activateEditCompanyName()}>
                  <svg width="16" height="16">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconEdit" />
                  </svg>
                </span>
                :
                null
            }
          </p>
        </div>
      );
    }
  }

  updateName(form) {
    this.props.dataActions.updateName(form.firstName, form.lastName);
    this.deactivateName();
  }

  deactivateName() {
    this.setState({ editName: false });
  }

  activateName() {
    this.setState({ editName: true });
  }

  updateZipCode(form) {
    this.props.dataActions.updateZipCode(form.zipcode);
    this.deactivateEditZipCode();
  }

  deactivateEditZipCode() {
    this.setState({ editZipCode: false });
  }

  activateEditZipCode() {
    this.setState({ editZipCode: true });
  }

  renderZipCode() {
    const { profile } = this.props;
    let zipCodeValue = profile.digitalIdentity.zipCode;
    if (zipCodeValue == null) {
      zipCodeValue = "";
    }
    if (profile.digitalIdentity != null) {
      if (this.state.editZipCode) {
        return (
          <Form
            actionUrl=""
            messageError=""
            customSubmit={this.updateZipCode} >
            <div className="inline-form">
              <TextField type="number" name="zipcode" placeholder={getDictionaryValue('zipCode', 'Zip Code')} label={getDictionaryValue('zipCode', 'Zip Code')} labelClass="imc-type--title-7 imc-type--bold zip-code" value={zipCodeValue} inputCls="imc-profile--input"></TextField>
              <button onClick={e => this.deactivateEditZipCode()} className="imc-button imc-button--accent1-inverted imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">{getDictionaryValue('discard', 'DIscard')}</button>
              <SubmitButton
                labelSubmit={getDictionaryValue('save', 'Save')}
                additionalClass="imc-button imc-button--secondary imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">
              </SubmitButton>
            </div>
          </Form>
        );
      } else {
        return (
          <div className="imc-formfield imc-content">
            <div className="imc-vr--xsmall "><label className="imc-type--title-7 imc-type--bold">Zip Code</label></div>
            <p className='imc-type--title-7'>
              {profile.digitalIdentity.zipCode}
              {
                (!this.isDemandDriver()) ?
                  <span onClick={e => this.activateEditZipCode()}>
                    <svg width="16" height="16">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconEdit" />
                    </svg>
                  </span>
                  :
                  null
              }
            </p>
          </div>
        );
      }
    } else {
      return null;
    }
  }

  renderName() {
    const { profile } = this.props;
    let firstNameValue = profile.firstName;
    let lastNameValue = profile.lastName;
    if (firstNameValue == null) {
      firstNameValue = "";
    }
    if (lastNameValue == null) {
      lastNameValue = "";
    }

    if (this.state.editName) {
      return (
        <Form
          actionUrl=""
          messageError=""
          customSubmit={this.updateName} >
          <div className="inline-form">
            <TextField type="text" name="firstName" placeholder={getDictionaryValue('firstName', 'First Name')} label={getDictionaryValue('firstName', 'First Name')} labelClass="imc-type--title-7 imc-type--bold first-name" value={firstNameValue} inputCls="imc-profile--input"></TextField>
            <TextField type="text" name="lastName" placeholder={getDictionaryValue('lastName', 'Last Name')} label={getDictionaryValue('lastName', 'Last Name')} labelClass="imc-type--title-7 imc-type--bold last-name" value={lastNameValue} inputCls="imc-profile--input"></TextField>
            <button onClick={e => this.deactivateName()} className="imc-button imc-button--accent1-inverted imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">{getDictionaryValue('discard', 'Discard')}</button>
            <SubmitButton
              labelSubmit={getDictionaryValue('save', 'Save')}
              additionalClass="imc-button imc-button--secondary imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">
            </SubmitButton>
          </div>
        </Form>
      );
    } else {
      return (
        <div className="imc-formfield imc-content">

          <div className='imc-vr--large imc-vr--smallmedium-desktop'>
            <p className='imc-type--title-4 imc-type--bold'>{getDictionaryValue('name', 'Name')}</p>
            <p className='imc-type--title-7'>{profile.firstName} {profile.lastName}
              {
                (!this.isDemandDriver()) ?
                  <span onClick={e => this.activateName()}>
                    <svg width="16" height="16">
                      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#IconEdit" />
                    </svg>
                  </span>
                  :
                  null
              }</p>
          </div>
        </div>
      );
    }
  }

  openRegistrationPopup() {
    this.setState({ isOpenRegistrationPopup: true })
  }

  openLinkExhibitorPopup() {
    this.setState({ isOpenLinkExhibitorPopup: true })
  }

  openAddEmailPopup() {
    this.setState({ isOpenAddNewEmail: true })
  }

  openDeleteEmailConfirm(mail) {
    this.setState(
      {
        isOpenDeleteEmailConfirm: true,
        selectedEmailToDelete: mail
      }
    )
  }

  openSetAsUsernameConfirm(mail) {
    this.setState(
      {
        isOpenSetAsUsernameConfirm: true,
        selectedEmailToSetAsUsername: mail
      }
    )
  }

  openResetPasswordConfirm(mail) {
    this.setState(
      {
        isOpenResetPassword: true
      }
    )
  }

  resetPassword() {
    this.props.dataActions.resetPassword();
    this.handleClosePopup();
  }

  handleClosePopup() {
    this.setState(
      {
        isOpenLinkExhibitorPopup: false,
        isOpenRegistrationPopup: false,
        isOpenAddNewEmail: false,
        isOpenDeleteEmailConfirm: false,
        isOpenSetAsUsernameConfirm: false,
        isOpenResetPassword: false
      }
    );
  }

  changeFutureMarket(e) {
    this.setState({ selectedFutureMarkets: e.target.value });
  }

  submitNewRegistration(e) {
    const registederEmailAddress = e.registederEmailAddress;
    const futureMarket = e.futureMarket;
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(registederEmailAddress) && futureMarket != null && futureMarket != "") {
      this.handleClosePopup();
      this.props.dataActions.linkMyMarketEmail(registederEmailAddress, futureMarket);
    }
  }

  submitNewAssociation(e) {
    const exhibitorNameSelected = e.exhibitorNameSelected;
    this.handleClosePopup();
    this.props.dataActions.associateExhibitor(exhibitorNameSelected);
  }

  addEmail(e) {
    const newEmailAddress = e.newEmailAddress;
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(newEmailAddress)) {
      this.handleClosePopup();
      this.props.dataActions.addNewEmail(newEmailAddress);
    }
  }

  renderRegistrations() {
    const { nextMarketRegistration, futureMarkets } = this.props;
    const { demandDriver } = this.props.profile;

    var registrationSection;
    if (demandDriver != null && typeof demandDriver.registrations != 'undefined') {
      registrationSection = (
        <div className="imc-content--padded-small-left">
          {!!demandDriver.registrations && demandDriver.registrations.map((registration, index) => (
            <p key={index} className="imc-type--title-7 imc-section--margin-small">{registration.channelName} - {registration.marketName}</p>
          ))}
        </div>
      );
    } else {
      registrationSection = (
        <React.Fragment>
          <p className='imc-type--title-7 imc-section--margin-small imc-content--margin-left-small'>{getDictionaryValue('notCurrentlyRegistrations', "You're not currently linked to a Market registration non Exhibitor. To link your account, contact us below.")}</p>
          <a onClick={e => this.openRegistrationPopup()} className="imc-button--secondary-inverted imc-button--round imc-content--margin-left-small imc-button--full-mobile">{getDictionaryValue('linkARegistration', 'Link A Registration')}</a>
        </React.Fragment>
      );
    }

    var registerNow = null;

    if (nextMarketRegistration != null) {
      registerNow = <a href={nextMarketRegistration.registrationLink} target="_blank" className="imc-button--secondary-inverted imc-button--round imc-content--margin-left-small imc-button--full-mobile">{getDictionaryValue('registerNow', 'Register Now')}</a>;
    }

    let listFutMarkAux = [];
    if (!!futureMarkets) {
      for (let i = 0; i < futureMarkets?.length; i++) {
        const element = futureMarkets[i];
        listFutMarkAux.push(
          {
            code: element.marketId,
            displayValue: element.marketName + " - " + element.channelName
          }
        );
      }
    }
    var linkExhibitor = null;
    if (this.props.profile != null) {// && demandDriver == null //&& this.props.profile.revenueGenerators == null){
      linkExhibitor = <button onClick={e => this.openLinkExhibitorPopup()} className="imc-button--secondary-inverted imc-button--round imc-content--margin-left-small imc-button--full-mobile">{getDictionaryValue('linkAnExhibitor', 'Link An Exhibitor')}</button>
    }

    return (
      <div className='imc-vr--large imc-vr--smallmedium-desktop'>
        <hr />
        <p className='imc-type--title-4 imc-type--bold'>{getDictionaryValue('myMarketRegistrations', 'My Market Registrations')}</p>
        {registrationSection}
        {linkExhibitor}
        {registerNow}
        <ReactModal
          isOpen={this.state.isOpenRegistrationPopup}
          overlayClassName="imc-modal--overlay"
          className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto imc-profile"
        >
          <Form actionUrl="" customSubmit={this.submitNewRegistration} >
            <div className="imc-modal--content imc-section">
              <div
                className={`imc-market-planner-quick-add-modal__content-box
                    imc-vr--large imc-vr--xlarge-desktop`}
              >
                <h2 className="imc-type--title-4 imc-type--bold">
                  {getDictionaryValue('linkMyMarketRegistration', 'Link My Market Registration')}
                </h2>
                <p className="imc-type--title-7 imc-section--margin-medium">{getDictionaryValue("ifYouHaveRegisteredForMarket", "If you have registered for market, but your registration does not appear in your profile, please provide the following information to send a request to connect your registration with your account.")}</p>

                <DropDown
                  additionalClass={`imc-dropdown--secondary--thin imc-marketplanner--dropdown`}
                  label={getDictionaryValue("market", "Market")}
                  labelClass="imc-type--title-7 imc-type--bold"
                  name={'futureMarket'}
                  sortedOptions={listFutMarkAux}
                  updateValue={this.changeFutureMarket}
                  value={this.state.selectedFutureMarkets}
                  firstOption={getDictionaryValue("selectAMarket", "Select a Market")} />

                <TextField
                  type="email"
                  name="registederEmailAddress"
                  label={getDictionaryValue('registeredEmailAddress', 'Registered Email Address')}
                  labelClass="imc-type--title-7 imc-type--bold"
                  value={this.state.registeredEmailAddress}
                  additionalClass="imc-section--margin-medium"
                  inputCls="imc-profile--input"
                  placeholder={getDictionaryValue('registeredEmailAddress', 'Registered Email Address')}>
                </TextField>
                <div className="imc-section--margin-small imc-profile-modal">
                  <button
                    className="imc-button imc-button--right  imc-button--round imc-button--accent1-inverted imc-content--margin-left-small imc-section--margin-medium"
                    onClick={this.handleClosePopup}
                  >{getDictionaryValue('cancel', 'Cancel')}</button>
                  <SubmitButton
                    labelSubmit={getDictionaryValue('submit', 'Submit')}
                    additionalClass="imc-button--right imc-button--secondary  imc-button--round imc-content--margin-left-small imc-section--margin-medium">
                  </SubmitButton>
                </div>
              </div>
            </div>
            <button
              className="imc-modal--close"
              onClick={this.handleClosePopup}
            >X</button>
          </Form>
        </ReactModal>
        <ReactModal
          isOpen={this.state.isOpenLinkExhibitorPopup}
          overlayClassName="imc-modal--overlay"
          className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto imc-profile"
        >
          <Form actionUrl="" customSubmit={this.submitNewAssociation} >
            <div className="imc-modal--content imc-section">
              <div
                className={`imc-market-planner-quick-add-modal__content-box
                    imc-vr--large imc-vr--xlarge-desktop`}
              >
                <h2 className="imc-type--title-4 imc-type--bold">
                  Associate account with an exhibitor
                </h2>
                <p className="imc-type--title-7 imc-section--margin-medium">{getDictionaryValue("ifYouAreAssociatedWithAnExhibitor", "If you believe you should be associated with an Exhibitor at a Market, please enter the information below to send a request.")}</p>

                <TextField
                  type="text"
                  name="exhibitorNameSelected"
                  label={getDictionaryValue('exhibitorName', 'Exhibitor Name')}
                  labelClass="imc-type--title-7 imc-type--bold"
                  value={this.state.selectedExhibitorName}
                  additionalClass="imc-section--margin-medium"
                  inputCls="imc-profile--input"
                  placeholder={getDictionaryValue('exhibitorName', 'Exhibitor Name')}>
                </TextField>
                <div className="imc-section--margin-small imc-profile-modal">
                  <button
                    className="imc-button imc-button--right imc-button--accent1-inverted imc-button--round imc-content--margin-left-small imc-section--margin-medium"
                    onClick={this.handleClosePopup}
                  >{getDictionaryValue('cancel', 'Cancel')}</button>
                  <SubmitButton
                    labelSubmit={getDictionaryValue('submit', 'Submit')}
                    additionalClass="imc-button--right imc-button--secondary  imc-button--round imc-content--margin-left-small imc-section--margin-medium">
                  </SubmitButton>
                </div>
              </div>
            </div>
            <button
              className="imc-modal--close"
              onClick={this.handleClosePopup}
            >X</button>
          </Form>
        </ReactModal>
      </div>
    );
  }

  renderAccountDeletionSection() {
    return (
      <div className='imc-vr--large imc-vr--smallmedium-desktop'>
        <hr />
        <p
          style={{ width: 'fit-content', paddingTop: '28px' }}
          className="imc-link"
          onClick={() => this.toggleAccountDeletionModal(true)}
        >Delete My Account</p>
      </div>
    )
  }

  renderAssociatedExhibitors() {
    const { profile } = this.props;
    var revenueGenerators = profile.revenueGenerators;
    if (revenueGenerators != null && revenueGenerators?.length > 0) {
      return (
        <div className='imc-vr--large imc-vr--smallmedium-desktop'>
          <p className='imc-type--title-4 imc-type--bold'>{getDictionaryValue('associatedExhibitors', 'Associated Exhibitors')}</p>
          <div>
            {revenueGenerators.map((item, index) => (
              <p key={index} className="imc-type--title-7-link imc-content--margin-left-small">{item.exhibitorName} ({item.role})</p>
            ))}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderAssociatedAsDemandDriver() {
    const { profile } = this.props;
    var demandDriver = profile.demandDriver;
    if (demandDriver != null) {
      return (
        <div className='imc-vr--large imc-vr--smallmedium-desktop'>
          <p className='imc-type--title-4 imc-type--bold'>{getDictionaryValue('associatedAsDemandDriver', 'Associated As DemandDriver')}</p>
          <div>
            <p className="imc-type--title-7-link imc-content--margin-left-small">{demandDriver.demandDriverName}</p>
          </div>
        </div>
      );
    }
  }

  renderAssociations() {
    return (
      <div>
        <div className="imc-gallery imc-gallery--1-2">
          <div className="imc-gallery__item imc-gallery__item--no-padding-left">
            {this.renderAssociatedExhibitors()}
          </div>
          <div className="imc-gallery__item imc-gallery__item--no-padding-left">
            {this.renderAssociatedAsDemandDriver()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { profile } = this.props;
    const { addNewEmailResponse } = this.props;
    if (typeof profile != 'undefined' && profile != null) {
      var digitalIdentitySection = null;
      if (profile.digitalIdentity != null) {
        var attendeeType = "";
        var companyType = "";
        if (profile.digitalIdentity != null && profile.digitalIdentity.attendeeType != null) {
          attendeeType = profile.digitalIdentity.attendeeType.key;
        }

        if (profile.digitalIdentity != null && profile.digitalIdentity != null) {
          companyType = profile.digitalIdentity.companyType.key;
        }

        var digitalIdentitySection = (
          <div className='imc-vr--large imc-vr--smallmedium-desktop'>
            <hr />
            <p className='imc-type--title-4 imc-type--bold imc-section--margin-top-xlarge imc-section--margin-bottom-large'>{getDictionaryValue('productCategoriesOfInterests', 'Product Categories Of Interests')}</p>
            <div className="imc-gallery imc-gallery--1-2">
              <div className="imc-gallery__item imc-gallery__item--no-padding-left ">
                {this.renderProductInterest()}
              </div>
              <div className="imc-gallery__item imc-gallery__item--no-padding-left">
                <div className="imc-formfield imc-dropdown imc-dropdown--secondary--thin">
                  <label className="imc-type--title-7 imc-type--bold" htmlFor="dropdown-attendee-type">
                    {getDictionaryValue('attendeeType', 'Attendee Type')}
                  </label>
                  <select id="dropdown-attendee-type" onChange={e => this.changeAttendeeType(e)} value={attendeeType}>
                    <option value="">{getDictionaryValue('selectAnOption', 'Select an Option')}</option>
                    {this.renderAttendeeOptions()}
                  </select>
                </div>
              </div>
            </div>

            <div className="imc-gallery imc-gallery--1-2 imc-section--margin-small">
              <div className="imc-gallery__item imc-gallery__item--no-padding-left ">
                {this.renderZipCode()}
              </div>
              <div className="imc-gallery__item imc-gallery__item--no-padding-left ">
                <div className="imc-formfield imc-dropdown imc-dropdown--secondary--thin">
                  <label className="imc-type--title-7 imc-type--bold" htmlFor="dropdown-attendee-type">
                    {getDictionaryValue('companyType', 'Company Type')}
                  </label>
                  <select id="dropdown-company-type" className="imc-dropdown" onChange={e => this.changeCompanyType(e)} value={companyType}>
                    <option value="">{getDictionaryValue('selectAnOption', 'Select an Option')}</option>
                    {this.renderCompanyTypeOptions()}
                  </select>
                </div>
              </div>
            </div>
          </div>
        );
      }

      var associations;
      if ((profile.revenueGenerators != null && profile.revenueGenerators?.length > 0) || profile.demandDriver != null) {
        associations = (
          <div>
            <hr />
            {this.renderAssociations()}
          </div>
        );
      }

      return (
        <div className="imc-profile">
          <button onClick={e => this.openResetPasswordConfirm(e)} className="imc-button imc-button--reset-password imc-button--accent1-inverted imc-addnote-modal__submit imc-button--radius-3 imc-button--border-2 imc-button--width-small imc-content--margin-left-small">{getDictionaryValue('resetPassword', 'Reset Password')}</button>
          <Loading showLoading={this.props.showLoading} />
          <ReactModal
            isOpen={this.state.isOpenResetPassword}
            overlayClassName="imc-modal--overlay"
            className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto imc-profile"
          >
            <div className="imc-modal--content imc-section">
              <div
                className={`imc-market-planner-quick-add-modal__content-box
                      imc-vr--large imc-vr--xlarge-desktop`}
              >
                <h2 className="imc-heading--giga imc-heading--secondary imc-vr--large">
                  Are you sure you want to reset password?
                </h2>
                <button
                  className="imc-market-planner-quick-add-modal__submit-btn imc-button imc-button--secondary imc-button--round"
                  onClick={this.resetPassword}
                >{'Confirm'}</button>
              </div>
            </div>
            <button
              className="imc-modal--close"
              onClick={this.handleClosePopup}
            >X</button>
          </ReactModal>
          {this.renderName()}
          <div className='imc-vr--large imc-vr--smallmedium-desktop'>
            <p className='imc-type--title-4 imc-type--bold'>{getDictionaryValue('emailAddresses', 'Email Address')}</p>
            <div className='imc-type--title-7 imc-section--margin-medium">'>
              <span className="imc-type--bold">{profile.preferredEmail}</span>
              {this.renderEmails()}
              <ReactModal
                isOpen={this.state.isOpenDeleteEmailConfirm}
                overlayClassName="imc-modal--overlay"
                className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto imc-profile"
              >
                <div className="imc-modal--content imc-section">
                  <div
                    className={`imc-market-planner-quick-add-modal__content-box
                          imc-vr--large imc-vr--xlarge-desktop`}
                  >
                    <h2 className="imc-heading--giga imc-heading--secondary imc-vr--large">
                      Are you sure you want to delete '{this.state.selectedEmailToDelete}' email?
                    </h2>
                    <button
                      className="imc-market-planner-quick-add-modal__submit-btn imc-button imc-button--secondary imc-button--round"
                      onClick={this.deleteSelectedEmail}
                    >{'Confirm'}</button>
                  </div>
                </div>
                <button
                  className="imc-modal--close"
                  onClick={this.handleClosePopup}
                >X</button>
              </ReactModal>
              <ReactModal
                isOpen={this.state.isOpenSetAsUsernameConfirm}
                overlayClassName="imc-modal--overlay"
                className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto imc-profile"
              >
                <div className="imc-modal--content imc-section">
                  <div
                    className={`imc-market-planner-quick-add-modal__content-box
                          imc-vr--large imc-vr--xlarge-desktop`}
                  >
                    <h2 className="imc-heading--giga imc-heading--secondary imc-vr--large">
                      Are you sure you want to set '{this.state.selectedEmailToSetAsUsername}' as username?
                    </h2>
                    <button
                      className="imc-market-planner-quick-add-modal__submit-btn imc-button imc-button--secondary imc-button--round"
                      onClick={this.setAsUsernameSelectedEmail}
                    >{'Confirm'}</button>
                  </div>
                </div>
                <button
                  className="imc-modal--close"
                  onClick={this.handleClosePopup}
                >X</button>
              </ReactModal>
              <p className="imc-section--margin-medium">
                <a className="imc-type--title-7-link imc-content--pointer" onClick={e => this.openAddEmailPopup()}>{getDictionaryValue('add', 'Add')}</a>
              </p>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.isOpenAddNewEmail}
            overlayClassName="imc-modal--overlay"
            className="imc-modal--box imc-market-planner-quick-add-modal imc-modal--overlay imc-modal--box-height-auto imc-profile"
          >
            <div className="imc-modal--content imc-section">
              <div
                className={`imc-market-planner-quick-add-modal__content-box
                    imc-vr--large imc-vr--xlarge-desktop`}
              >
                <h2 className="imc-type--title-4 imc-type--bold">
                  Add another Email Address
                </h2>
                <p className="imc-type--title-7 imc-section--margin-medium">{getDictionaryValue("addingAnotherEmailAddress", "Adding another email address to your account will send a confirmation email. Once confirmed, the new address may be assigned as your primary address to be used as your username and login.")}</p>
                <Form
                  actionUrl=""
                  messageError="Invalid input"
                  customSubmit={this.addEmail} >
                  <div className="inline-form">
                    <TextField
                      type="email"
                      name="newEmailAddress"
                      label={getDictionaryValue('newEmailAddress', 'New Email Address')}
                      labelClass="imc-type--title-7 imc-type--bold"
                      value={this.state.newEmailAddress}
                      additionalClass="imc-section--margin-medium"
                      inputCls="imc-profile--input imc-profile--input-inline">
                    </TextField>
                    <SubmitButton
                      labelSubmit={getDictionaryValue('verify', 'Verify')}
                      additionalClass="imc-button--secondary imc-button--round imc-content--margin-left-small imc-section--margin-medium ">
                    </SubmitButton>
                  </div>
                  <div className="imc-section--margin-small">
                    <button
                      className="imc-button--right imc-button--secondary imc-button--round imc-content--margin-left-small imc-section--margin-medium"
                      onClick={this.handleClosePopup}
                    >{getDictionaryValue('cancel', 'Cancel')}</button>
                  </div>
                </Form>
              </div>
            </div>
            <button
              className="imc-modal--close"
              onClick={this.handleClosePopup}
            >X</button>
          </ReactModal>
          {this.renderCompanyName()}
          {digitalIdentitySection}
          {associations}
          {this.renderRegistrations()}
          {this.renderAccountDeletionSection()}
          {/* First account deletion modal */}
          {(this.state.isAccountDeletionModalOpen ||
            this.state.isAccountDeletionAssociationModalOpen) &&
            <GenericModal
              children={this.props.profile.revenueGenerators?.length > 0 ?
                deleteModalContentWithAssociations : deleteModalContentDefault
              }
              onClose={() => this.toggleAccountDeletionModal(false)}
              onAccept={() => this.toggleDeleteConfirmationModal(true)}
              acceptButtonTitle="Delete My Account"
              denyButtonTitle='Cancel'
              title="Delete Your Account"
            />}
          {/* Confirm deletion of account */}
          {this.state.isAccountDeletionConfirmationModalOpen && (
            <GenericModal
              children="Your account will be deleted within three business days. A confirmation email will be sent to the email address on record."
              onClose={() => this.toggleDeleteConfirmationModal(false)}
              onAccept={() => this.deleteAccount()}
              acceptButtonTitle="Okay"
              title="Account Deleted"
            />
          )}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Loading showLoading={this.props.showLoading} showPageLoading={false} />
        </React.Fragment>
      )
    }
  }
}

function mapStateToProps(state) {
  if (!!state.profileReducer) {

    var map = {};
    if (!!state.profileReducer) {
      if (!!state.profileReducer.profile)
        map.profile = state.profileReducer.profile;
      if (!!state.profileReducer.attendeeTypes)
        map.attendeeTypes = state.profileReducer.attendeeTypes;
      if (!!state.profileReducer.companyTypes)
        map.companyTypes = state.profileReducer.companyTypes;
      if (!!state.profileReducer.updateAttendeeResponse)
        map.updateAttendeeResponse = state.profileReducer.updateAttendeeResponse;
      if (!!state.profileReducer.productInterest)
        map.productInterest = state.profileReducer.productInterest;
      if (!!state.profileReducer.nextMarketRegistration)
        map.nextMarketRegistration = state.profileReducer.nextMarketRegistration;
      if (!!state.profileReducer.futureMarkets)
        map.futureMarkets = state.profileReducer.futureMarkets;
      if (!!state.profileReducer.deleteEmailResponse)
        map.deleteEmailResponse = state.profileReducer.deleteEmailResponse;
      if (!!state.profileReducer.setEmailAsDefaultResponse)
        map.setEmailAsDefaultResponse = state.profileReducer.setEmailAsDefaultResponse;
      if (!!state.profileReducer.addNewEmailResponse) {
        map.addNewEmailResponse = state.profileReducer.addNewEmailResponse;
      }
      if (!!state.profileReducer.showLoading) {
        map.showLoading = state.profileReducer.showLoading;
      }
    }
    return map;
  } else {
    return {
      profile: state.profile,
      attendeeTypes: state.attendeeTypes,
      companyTypes: state.companyTypes,
      updateAttendeeResponse: state.updateAttendeeResponse,
      productInterest: state.productInterest,
      nextMarketRegistration: state.nextMarketRegistration,
      futureMarkets: state.futureMarkets,
      deleteEmailResponse: state.deleteEmailResponse,
      setEmailAsDefaultResponse: state.setEmailAsDefaultResponse,
      addNewEmailResponse: state.addNewEmailResponse,
      updateCompanyNameResponse: state.updateCompanyNameResponse,
      showLoading: state.showLoading,
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dataActions: bindActionCreators({ ...dataActions }, dispatch),
  };
}

export default withSitecoreContext()(withSitecoreRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileComponent)));