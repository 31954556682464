import React, { Component } from 'react';
import SelectableIcon from 'modules/selectableicon';
import { Carousel, CarouselItem } from 'modules/carousel';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {withSitecoreRouter} from 'utils/withRouter';

// // Module dependencies
import Pagination from 'modules/search/Pagination.jsx';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

// action imports
import * as dataActions from './actions/dataActions';
import * as productActions from './actions/productsActions';
import * as filterActions from './actions/filterActions';

import SearchItemThumb from 'modules/searchItemThumb';

//Utils
import { exhibitorIsLine } from "utils/exhibitor";
import UserUtility from "utils/userutility";
import ImcDataLayer from "utils/datalayer";
import { isEditorActive } from "@sitecore-jss/sitecore-jss-react";

/**
 * @property defaultProps
 * @type {{products: *, filters: *, selectedTab: *, resultsPerPage: *, pagenum: *, dataActions: *, filterActions: *,
 * actions: *, lineDetail: *, productsCount: *, exhibitorLabels: *, filterErr: *, exhibitorName: *, filterKey: *,
 * savedContent: *}}
 */
const propTypes = {
    products: PropTypes.array.isRequired,
    featuredProducts: PropTypes.array.isRequired,
    filters: PropTypes.object,
    selectedTab: PropTypes.object,
    resultsPerPage: PropTypes.number.isRequired,
    pagenum: PropTypes.number.isRequired,
    //updateUrl: PropTypes.func.isRequired,
    dataActions: PropTypes.object,
    filterActions: PropTypes.object,
    actions: PropTypes.object,
    lineDetail: PropTypes.bool,
    productsCount: PropTypes.number,
    exhibitorLabels: PropTypes.object,
    filterErr: PropTypes.number,
    exhibitorName: PropTypes.string,
    filterKey: PropTypes.string,
    savedContent: PropTypes.object,
    location: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{lineDetail: boolean, products: {}, pagenum: number, resultsPerPage: number, filters: {},
 * dataActions: {fetchProducts: defaultProps.dataActions.fetchProducts},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * actions: {}, productsCount: number, exhibitorLabels: {},
 * filterErr: null, globalLabels: {pimCatalogImageUrl: string}, exhibitorName: string, filterKey: string,
 * savedContent: {}}}
 */
const defaultProps = {
    lineDetail: false,
    products: [],
    featuredProducts: [],
    pagenum: 1,
    resultsPerPage: 15,
    filters: {},
    dataActions: {
        fetchProducts: () => { },
        fetchFeaturedProducts: () => { },
    },
    filterActions: {
        getFilters: () => { },
    },
    productsCount: 0,
    exhibitorLabels: {
        productCount: 'Showing $1 Products',
        productQueryPrefix: 'for',
        seeAllProducts: 'Click here to see all products',
    },
    filterErr: null,
    globalLabels: {
        pimCatalogImageUrl: 's3.amazonaws.com',
    },
    exhibitorName: '',
    filterKey: 'products',
    savedContent: {},
    location: {},
};

/**
 * TabMyLists component
 * @constructor
 */
class TabProducts extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        // const queryObj = qs.parse(this.props.router.location.search);
        this.state = {
            errors: [],
            carouselKey: 0,
            pageNum: 1,
            loading: false,
            multiSelectMode: false,
        }
        this.createProductElements = this.createProductElements.bind(this);
        this.renderProducts = this.renderProducts.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.renderNoResults = this.renderNoResults.bind(this);
        this.renderCarousel = this.renderCarousel.bind(this);
        this.renderCarouselItem = this.renderCarouselItem.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        const { sitecoreContext } = this.props;
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.exhibitor !== 'undefined') {
            this.exhibitId = sitecoreContext.exhibitor.id;
        }
        if (typeof sitecoreContext !== 'undefined' && typeof sitecoreContext.lineProduct !== 'undefined' && typeof sitecoreContext.lineProduct.lineId !== 'undefined') {
            this.lineId = sitecoreContext.lineProduct.lineId;
        }
    }

    GTMPushProductImpressions(products){
        ImcDataLayer.PushProductImpressions(products);
    }

    componentDidUpdate(prevProps){
        const {tabData} = this.props;
        if (!isEditorActive()) {

            if (tabData && tabData.products &&  Object.keys(tabData.products).length>0 && prevProps.tabData &&  prevProps.tabData.products != tabData.products) {
                this.GTMPushProductImpressions(tabData.products);
            }
        }
    }
    /**
    * Returns an product carousel item
    * @param {Object} product product object froms earch
    * @returns {XML} A carousel item
    */
    renderCarouselItem(product) {
        return (
            <CarouselItem
                key={product.id}
            >
                {this.createProductElements(product)}
            </CarouselItem>
        );
    }

    /**
     * @returns {XML} A carousel of the featured articles
     */
    renderCarousel() {
        const { featuredProducts } = this.props;
        return (
            <Carousel
                carouselColumns={4}
                // resetIndex={true}
                key={this.state.carouselKey}
            >
                {featuredProducts.map(product => this.renderCarouselItem(product))}
            </Carousel>
        );
    }

    /**
     * creates individual line elements: TBD : move to a separate file
     * @param {object} product single line element
     * @returns {*} Rendered component
     */
    createProductElements(product) {
        const lineProdDetailUrl = '/exhibitor/$1/line/$2/prod/$3';
        const productDetailUrl = '/exhibitor/$1/prod/$2';

        const lineDetail = exhibitorIsLine(product);
        const _url = (!!product.lineId && product.lineId > 0) ?
            `${lineProdDetailUrl}`.replace('$1', product.exhibitorId).replace('$2', product.lineId)
                .replace('$3', product.uniqueId) :
            `${productDetailUrl}`.replace('$1', product.exhibitorId).replace('$2', product.uniqueId);

        let desktopPath = '';
        let tabletPath = '';
        let mobilePath = '';
        let altText = '';
        // seek Default Image
        if (product.images && product.images.length > 0) {
            let index = 0;
            for (let i = 0; i < product.images.length; i++) {
                if (product.images[i].defaultImage === 'Yes') {
                    index = i;
                    break;
                }
            }

            desktopPath = product.images[index].fullPath;
            tabletPath = product.images[index].fullPath;
            mobilePath = product.images[index].fullPath;
            altText = product.images[index].altText;
        }
        const mainType = lineDetail ? 'line' : 'exhibitor';
        const itemId = lineDetail ? product.lineId : product.exhibitorId;
        const data = {
            itemId: itemId,
            itemType: mainType,
            label: product.productTitle,
            itemContents: [{
                deleted: true, //Leave this in true for adding and item thru the copy endpoint
                contentName: product.productTitle,
                itemId: product.uniqueId,
                itemType: product.type//'Product',
            }],
        };
        // Constructs the final image path
        const finalImageMobile = mobilePath.length > 0 ?
            `${mobilePath}` :
            'placeholderImagePath';
        const finalImageTablet = tabletPath.length > 0 ?
            `${tabletPath}` :
            'placeholderImagePath';
        const finalImageDesktop = desktopPath.length > 0 ?
            `${desktopPath}` :
            'placeholderImagePath';
        // const itemDetails = {
        //     "lastModifiedDate":"2020-05-06 13:25:31",
        //     "toListId":[23948],
        //     "modifyUserListItems":[{
        //         "itemId":"15320",
        //         "itemType":"exhibitor",
        //         "deleteItem":false,
        //         "itemContents":[{
        //             "itemType":"Product",
        //             "contentName":"Infinity IT-8500",
        //             "itemId":"1240020"
        //         }]
        //     }],
        //     "accountId":"30958"
        // }
        return (
            <SearchItemThumb
                mainClass="imc-catalog"
                extraClass={'imc-catalog__item-third imc-catalog__item-expanded'}
                key={product.uniqueId}
                url={_url}
                image={finalImageTablet}
                newWindow={false}
                actionItem={data}
                name={product.productTitle}
                lineName={product.lineName}
                itemID={product.uniqueId}
                product={product}
                width={273}
                height={273}
                isNew={product.newProductForMarket}
            />

        );
    }

    /**
     * @method constructor
     * @param {array} products Incoming props
     * @returns {*} Components
     */
    renderProducts(products) {
        return (Object.keys(products).length) ? Object.keys(products).map(
            index => (
                this.createProductElements(products[index])
            ),
        ) : '';
    }

    /**
     * No results content
     * @returns {*} JSX for no results
     */
    renderNoResults() {
        if (typeof window === 'undefined' || (typeof window != 'undefined' && !window.Sitecore) || !("currentTab" in this.props))
            return <></>;
        const label = this.props.fields.clickToProducts.value;
        let query = '';
        if (typeof window != 'undefined' || (typeof window != 'undefined' && !window.Sitecore)) {
            query = qs.parse(window.location.search).q;
        }
        const type = (this.props.currentTab && this.props.currentTab.type) ? this.props.currentTab.type.value : '';
        return (
            <div className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
            imc-searchresults`}>
                <div className="imc-vr--large imc-content imc-stacked">
                    There are no matching search results
                    {(query !== '' && query !== undefined) ?
                        <span> for <strong>&quot;{query}&quot;</strong></span> : null
                    }
                </div>
                <div className="imc-vr--large imc-content imc-stacked">
                    <a
                        href={`/exhibitor/${this.exhibitId}/${type.toLowerCase()}`}
                        className="imc-link imc-link--alt-darkred imc-link--caret-after">
                        {label}
                    </a>
                </div>
            </div>
        );
    }

    /**
     * Renders the product results
     * @returns {*} Product results JSX
     */
    renderResults() {
        const products = this.props.tabData.products || [];
        const {
            featuredProducts,
            tabData,
            sitecoreContext
        } = this.props;
        const prodCount = tabData.prodCount;
        const size = this.props.currentTab && this.props.currentTab.size? this.props.currentTab.size.value: 30;
        const numberOfPages =  Math.ceil(parseInt(prodCount,10) / size);
        if (Object.keys(products).length) {
            return (
                <div
                    className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
                    imc-searchresults`}
                >
                    <div
                        className={`imc-gallery imc-gallery--align-flex-start imc-gallery--1-4 imc-vr--collosal
                        imc-fprod-cont imc-catalog`}
                    >
                        {/* +++++++++++++++++++++++++++++++++++++++++++
                            TODO - When Real data comes, add functionality
                            to add featured product carouel
                        +++++++++++++++++++++++++++++++++++++++++++*/}
                        {/* && parseFloat(pageNum) === 1 add to */}
                        {(featuredProducts !== undefined && featuredProducts.length > 0) &&
                            <div className={'imc-gallery--featured-products-wrap'}>
                                <h2
                                    className={'imc-heading imc-heading--secondary imc-heading--alpha'}
                                >
                                    Featured Products
                                </h2>
                                {/* {this.renderCarousel()} */}
                            </div>
                        }
                        {products ? this.renderProducts(products) : []}
                    </div>
                    <Pagination totalPages={numberOfPages} nonSearch />
                </div>
            );
        }
        return this.renderNoResults();
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const {
            tabData,
        } = this.props;

        return (
            <div
                className={`imc-gallery imc-gallery--align-flex-start
                imc-gallery--padding-left imc-vr--collosal`}
            >
                <div className="imc-gallery__item imc-exhibitorheader imc-gallery__item__product-list">
                    <div className="imc-vr--large">
                        <Placeholder name='imc-search-bar' rendering={this.props.rendering} />
                    </div>
                    {tabData && tabData.products &&
                        this.renderResults()
                    }
                </div>
            </div>
        );
    }
}


/**
 * Maps dispatch to props for connect
 * @param {function} dispatch Dispatcher
 * @returns {object} Action creators
 */
function mapDispatchToProps(dispatch) {
    return {
        dataActions: bindActionCreators({ ...dataActions }, dispatch),
        filterActions: bindActionCreators({ ...filterActions }, dispatch),
        productActions: bindActionCreators({ ...productActions }, dispatch),
    };
}

TabProducts.propTypes = propTypes;
TabProducts.defaultProps = defaultProps;

// Export the react component
export default withSitecoreContext()(
    withSitecoreRouter(connect(null, mapDispatchToProps)(TabProducts))
);
