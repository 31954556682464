

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

// // Module dependencies
import Pagination from 'modules/search/Pagination.jsx';

import {EventCard} from 'modules/eventcard'
import { SearchController } from 'modules/search/controllers/SearchController.jsx';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import UserUtility from "utils/userutility";


/**
 * @property defaultProps
 * @type {{exhibitors: *, filters: *, selectedTab: *, resultsPerPage: *, pagenum: *, dataActions: *, filterActions: *,
 * actions: *, lineDetail: *, exhibitorsCount: *, exhibitorLabels: *, filterErr: *, exhibitorName: *, filterKey: *,
 * savedContent: *}}
 */
const propTypes = {
  exhibitors: PropTypes.array.isRequired,
  featuredExhibitors: PropTypes.array.isRequired,
  filters: PropTypes.object,
  selectedTab: PropTypes.object,
  resultsPerPage: PropTypes.number.isRequired,
  pagenum: PropTypes.number.isRequired,
  //updateUrl: PropTypes.func.isRequired,
  dataActions: PropTypes.object,
  filterActions: PropTypes.object,
  actions: PropTypes.object,
  lineDetail: PropTypes.bool,
  exhibitorsCount: PropTypes.number,
  exhibitorLabels: PropTypes.object,
  filterErr: PropTypes.number,
  exhibitorName: PropTypes.string,
  filterKey: PropTypes.string,
  savedContent: PropTypes.object,
  location: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{lineDetail: boolean, exhibitors: {}, pagenum: number, resultsPerPage: number, filters: {},
 * dataActions: {fetchExhibitors: defaultProps.dataActions.fetchExhibitors},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * actions: {}, exhibitorsCount: number, exhibitorLabels: {},
 * filterErr: null, globalLabels: {pimCatalogImageUrl: string}, exhibitorName: string, filterKey: string,
 * savedContent: {}}}
 */
const defaultProps = {
  lineDetail: false,
  exhibitors: [],
  featuredExhibitors: [],
  pagenum: 1,
  resultsPerPage: 15,
  dataActions: {
    fetchExhibitors: () => { },
    fetchFeaturedExhibitors: () => { },
  },
  filterActions: {
    getFilters: () => { },
  },
  actions: {
  },
  exhibitorsCount: 0,
  filterErr: null,
  globalLabels: {
    pimCatalogImageUrl: 's3.amazonaws.com',
  },
  exhibitorName: '',
  filterKey: 'products',
  savedContent: {},
  location: {},
  tab: 'information',
};


/**
 * TabMyLists component
 * @constructor
 */
export default class SearchResultsEventsComponent extends SearchController {
  /**
   * @method constructor
   * @param {object} props Incoming props
   */
  constructor(props) {
    super(props);
    // const queryObj = qs.parse(this.props.router.location.search);
    this.state = {
      // query: queryObj.q || '',
      errors: [],
      pageNum: 1,
      loading: true,
    }
    this.renderEventCard = this.renderEventCard.bind(this);
    this.renderEvents = this.renderEvents.bind(this);
    this.renderResultsTitle = this.renderResultsTitle.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.renderNoResults = this.renderNoResults.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Fetch up to data exhibitor data
   * @param { string } queryObj term used to conduct search for
   * @param { number } pageNumIn Incoming page number to use
   */
  fetchData(queryObj, pageNumIn) {
    const { q, ...filters } = queryObj;
    const { match, lineDetail, filterKey } = this.props;
    // const tabFilters = filters[filterKey] ? decode(filters[filterKey]) : {};
    let exhibitId;
    let isLine = false;
    if (lineDetail) {
      exhibitId = match.params.lineId;
      isLine = true;
    } else {
      exhibitId = match.params.exhibitId;
    }
    const pageNum = pageNumIn || 1;
    // this.props.dataActions.fetchExhibito(q, tabFilters, exhibitId, isLine, pageNum);
    // this.props.dataActions.fetchFeaturedExhibito(q, tabFilters, exhibitId, isLine, 1);
    this.setState({
      carouselKey: this.state.carouselKey + 1,
      pageNum,
    });
  }


  /**
     * Render the Exhibitors cards as appropriate for display pagination
     * @returns {HTML} Array of Exhibitor Cards
     * @param {object} exhibitor json with exhibitor information
     * @param { integer } index indexed value of exhibitor in exhibitor result list
     */
    renderEventCard(event, index) {
      const props = {
        search: true,
        locations: event.location,
        ...event
      };
    return <EventCard {...props} key={index} />
  }

  /**
   * @method constructor
   * @param {array} exhibitors Incoming props
   * @returns {*} Components
   */
  renderEvents(events) {
    return (events.length) ? events.map(
      (event, index) => (
        this.renderEventCard(event, index)
      ),
    ) : '';
  }

  /**
   * Updates the query in the state
   * @param { string } fieldname Name of the field
   * @param { string } query Query to update to the state
   */
  updateQuery(fieldname, query) {
    this.setState({
      query,
    });
  }

  onSubmit()
  {

  }
  /**
   * Renders the product text under the query field
   * @returns {*} Product results text
   */
  renderResultsTitle() {
    const {eventsCount} = this.props.tabData;
    let query;
    if (typeof window !== "undefined" && qs.parse(window.location.search).q !== undefined) {
      query = qs.parse(window.location.search).q;
    } else {
      query = '';
    }
    return (
      <div className="render-results-title">
        Showing {eventsCount} Results {(query !== '') ? 'for ' + query : ''}
        {eventsCount === 0 &&
         this.renderNoResults(query)
        }
      </div>

    );
  }

  /**
   * No results content
   * @returns {*} JSX for no results
   */
  renderNoResults(term) {
    const {
      exhibitorLabels,
    } = this.props;
    // const query = qs.parse(location.search).q;
    return (

        <div className="render-results-title">
        No results for {term} found in Events
      </div>

    );
  }

  /**
   * Renders the product results
   * @returns {*} Product results JSX
   */
  renderResults() {
    const { tabData } = this.props;
    const events = tabData.events || [];
    const { pageSize } = this.props;
    if (Array.isArray(events) && events.length > 0 && 'eventsCount' in tabData) {
      const numberOfPages = Math.ceil(parseInt(tabData.eventsCount, 10) / pageSize, 0);
      return (
        <div
          className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
                      imc-searchresults`}
        >
          <div className="imc-vr--xxlarge imc-margin--top--large--mobile" >
            {events ? this.renderEvents(events) : []}
          </div>
          <Pagination totalPages={numberOfPages} nonSearch />
        </div>
      );
    }

    // return (
    //   <div
    //     className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
    //                 imc-searchresults`}
    //   >
    //     <h2 className="imc-exhibitorheader__resultstitle">
    //       {this.renderResultsTitle()}
    //     </h2>
    //     {events && events.length !== 0 &&
    //     <FilterBarComponent></FilterBarComponent>}
    //     <div className="imc-vr--xxlarge" >
    //       {events ? this.renderEvents(events) : []}

    //     </div>
    //     <Pagination totalPages={numberOfPages} nonSearch />
    //   </div>
    // );

  }

  /**
   * @method render
   * @description Renders the DOM element
   * @returns {*} Rendered component
   */
  render() {
    const {
      filterErr,
      tabData,
      location, rendering
    } = this.props;
    const { query, loading } = this.state;
    const filters = tabData.filters;
    const gridClass = filterErr ? '' : 'imc-gallery--25-75';
    return (
      <>
        <div
          className={`imc-gallery imc-gallery--align-flex-start ${gridClass}
                imc-gallery--padding-left imc-vr--collosal`}
        >

          <div className={'imc-gallery__item imc-exhibitors__panel'}>
          <Placeholder name="imc-category-filter" rendering={rendering} />
          </div>
          <div className="imc-gallery__item imc-exhibitorheader">
            <div className="">
              <Placeholder name="imc-typeahead" rendering={rendering} />
            </div>
            {this.renderResults()}
          </div>
        </div >
      </>
    );
  }
}

SearchResultsEventsComponent.propTypes = propTypes;
SearchResultsEventsComponent.defaultProps = defaultProps;

