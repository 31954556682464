import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

// // Module dependencies
import Pagination from 'modules/search/Pagination.jsx';
import { ExhibitorCard } from 'modules/exhibitorcard';
import SearchController from 'modules/search/controllers/SearchController.jsx';
import UserUtility from "utils/userutility";
import exhibitorAPI from 'api/exhibitor';
import Link from '../Link';
import { richTextRendering } from 'utils/buildRenderingParameters';
import RichTextComponent from '../Rich-Text-Component';

/**
 * @property defaultProps
 * @type {{exhibitors: *, filters: *, selectedTab: *, pageSize: *, pagenum: *, dataActions: *, filterActions: *,
 * actions: *, lineDetail: *, exhibitorsCount: *, exhibitorLabels: *, filterErr: *, exhibitorName: *, filterKey: *,
 * savedContent: *}}
 */
const propTypes = {
  exhibitors: PropTypes.array.isRequired,
  featuredExhibitors: PropTypes.array.isRequired,
  filters: PropTypes.object,
  selectedTab: PropTypes.object,
  pageSize: PropTypes.number.isRequired,
  pagenum: PropTypes.number.isRequired,
  dataActions: PropTypes.object,
  filterActions: PropTypes.object,
  actions: PropTypes.object,
  lineDetail: PropTypes.bool,
  exhibitorsCount: PropTypes.number,
  exhibitorLabels: PropTypes.object,
  filterErr: PropTypes.number,
  exhibitorName: PropTypes.string,
  filterKey: PropTypes.string,
  savedContent: PropTypes.object,
};

/**
 * @property defaultProps
 * @type {{lineDetail: boolean, exhibitors: {}, pagenum: number, pageSize: number, filters: {},
 * dataActions: {fetchExhibitors: defaultProps.dataActions.fetchExhibitors},
 * filterActions: {getFilters: defaultProps.filterActions.getFilters},
 * actions: {}, exhibitorsCount: number, exhibitorLabels: {},
 * filterErr: null, globalLabels: {pimCatalogImageUrl: string}, exhibitorName: string, filterKey: string,
 * savedContent: {}}}
 */
const defaultProps = {
  lineDetail: false,
  exhibitors: [],
  featuredExhibitors: [],
  pagenum: 1,
  pageSize: 15,
  dataActions: {
    fetchExhibitors: () => { },
    fetchFeaturedExhibitors: () => { },
  },
  filterActions: {
    getFilters: () => { },
  },
  actions: {
  },
  exhibitorsCount: 0,
  exhibitorLabels: {
    productCount: 'Showing {{count}} Exhibitors',
    productQueryPrefix: 'for',
    seeAllExhibitors: 'Click here to see all exhibitors',
  },
  filterErr: null,
  globalLabels: {
    pimCatalogImageUrl: 's3.amazonaws.com',
  },
  exhibitorName: '',
  filterKey: 'products',
  savedContent: {},
  location: {},
};

/**
 * Key used for the Filter component
 * @type {string}
 */
const productsFilterKey = 'product';

/**
 * TabMyLists component
 * @constructor
 */
class SearchResultsExhibitorsComponent extends React.Component {
  /**
   * @method constructor
   * @param {object} props Incoming props
   */
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      carouselKey: 0,
      pageNum: 1,
      loading: true,
      exhibitorCounts: [],
    }

    this.api = new exhibitorAPI()
    this.renderExhibitorCard = this.renderExhibitorCard.bind(this);
    this.renderExhibitors = this.renderExhibitors.bind(this);
    this.updateQuery = this.updateQuery.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.renderNoResults = this.renderNoResults.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getExhibitorCounts = this.getExhibitorCounts.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { exhibitors } = this.props?.tabData;

    if (exhibitors && exhibitors !== prevProps?.tabData?.exhibitors) {
      this.getExhibitorCounts()
    }
  }

  /**
   * @method getExhibitorCounts
   * @description asynchronously gets an array of exhibitor counts and sets them to state in exhibitorCounts
   */
  async getExhibitorCounts() {
    const { exhibitors } = this.props?.tabData;

    const exhibitorIds = exhibitors.map((item) => item.exhibitorId)
    const exhibitorCounts = await this.api.getExhibitorCountArray(exhibitorIds)
    this.setState({ exhibitorCounts })
  }

  /**
   * Fetch filters relevant to exhibitors & products
   */
  getFilters() {
    const { match, lineDetail } = this.props;
    let exhibitId;
    let isLine = false;
    if (lineDetail) {
      exhibitId = match.params.lineId;
      isLine = true;
    } else {
      exhibitId = match.params.exhibitId;
    }
    this.props.filterActions.getFilters(productsFilterKey, exhibitId, isLine);
  }

  /**
   * Fetch up to data exhibitor data
   * @param { string } queryObj term used to conduct search for
   * @param { number } pageNumIn Incoming page number to use
   */
  fetchData(queryObj, pageNumIn) {
    const { q, ...filters } = queryObj;
    const { match, lineDetail, filterKey } = this.props;
    let exhibitId;
    let isLine = false;

    if (lineDetail) {
      exhibitId = match.params.lineId;
      isLine = true;
    } else {
      exhibitId = match.params.exhibitId;
    }
    const pageNum = pageNumIn || 1;
    this.setState({
      carouselKey: this.state.carouselKey + 1,
      pageNum,
    });
  }

  /**
     * Render the Exhibitors cards as appropriate for display pagination
     * @returns {HTML} Array of Exhibitor Cards
     * @param {object} exhibitor json with exhibitor information
     * @param { integer } index indexed value of exhibitor in exhibitor result list
     */
  renderExhibitorCard(exhibitor, index) {
    const { exhibitorCounts } = this.state;
    const { inSelectionState, selectMultiAdd, siteUrls} = this.props;
    // page numbers start at 1
    const { companyDetails, lease, products, exhibitorId, type, title, catalogs, productCount, locations, uniqueId } = exhibitor;
    const name = companyDetails ? companyDetails.companyName : title;
    return (
      <ExhibitorCard
        exhibitorId={exhibitorId}
        exhibitorName={name}
        inSelectionState={inSelectionState}
        key={`product-${index}`}
        title={title}
        lease={lease}
        products={products || []}
        onMultiSelect={selectMultiAdd}
        type={type}
        lineId={uniqueId}
        siteUrls={siteUrls}
        lineName={title}
        catalogs={catalogs || []}
        productCount={productCount}
        showroomLocation={locations}
        allowFavoriting={false}
        fallbackImagePath={process.env.PUBLIC_URL + '/assets/images/150-150.png'}
        exhibitor={exhibitor}
        exhibitorCount={exhibitorCounts.find((item) => item.exhibitorId === exhibitorId)}
      />
    );
  }

  /**
   * @method constructor
   * @param {array} exhibitors Incoming props
   * @returns {*} Components
   */
  renderExhibitors(exhibitors) {
    return (exhibitors.length) ? exhibitors.map(
      (exhibitor, index) => (
        this.renderExhibitorCard(exhibitor, index)
      ),
    ) : '';
  }

  /**
   * Updates the query in the state
   * @param { string } fieldname Name of the field
   * @param { string } query Query to update to the state
   */
  updateQuery(fieldname, query) {
    this.setState({
      query,
    });
  }

  onSubmit() {

  }

  /**
   * No results content
   * @returns {*} JSX for no results
   */
  renderNoResults() {
    const {
      exhibitorLabels,
    } = this.props;
    return (
      <div>
        <div className="imc-vr--large imc-content imc-stacked">
          {/* {exhibitorLabels.noExhibitors} */}
          {/* { (query !== '' && query !== undefined) ? */}
          {/* <span> {exhibitorLabels.productQueryPrefix} <strong>&quot;{'query'}&quot;</strong></span> : null */}
          {/* } */}
        </div>
        <div className="imc-vr--large imc-content imc-stacked">
          <a
            href={`#`}
            className="imc-link imc-link--alt-darkred imc-link--caret-after"
          >
            {'exhibitorLabels.seeAllExhibitors'}
          </a>
        </div>
      </div>
    );
  }

  /**
   * Renders the product results
   * @returns {*} Product results JSX
   */
  renderResults() {
    const { rendering } = this.props;
    const exhibitors = this.props.tabData.exhibitors || [];
    const numberOfPages = Math.ceil(parseInt(this.props.tabData.exhibitorsCount, 10) / this.props.pageSize);
    let selected = '';

    return (
      <div
        className={`imc-gallery--padding-left imc-gallery--align-flex-start imc-vr--xxxlarge
                    imc-searchresults`}
      >
        <div className="imc-vr--xxlarge" >

          {this.renderExhibitors(exhibitors && Array.isArray(exhibitors) ? exhibitors.slice(0, this.props.pageSize) : [])}
        </div>
        {
          exhibitors && Array.isArray(exhibitors) && exhibitors.length > 0 &&
          <Pagination totalPages={numberOfPages} nonSearch />
        }
      </div>
    );
  }

  /**
   * @method render
   * @description Renders the DOM element
   * @returns {*} Rendered component
   */
  render() {
    const {
      filterErr,
      tabData,
      rendering,
    } = this.props;
    const isRegistered = this.props.isRegistered;
    const isLoaded = this.props.planLoaded;
    const isLoggedIn = UserUtility.isLoggedIn();
    const filters = tabData.filters;
    const gridClass = filterErr ? '' : 'imc-gallery--25-75';
    const marketPlanRichText = richTextRendering();
    let showMarketPlanMessage = false;
    if (!isLoggedIn && this.props?.fields?.signedOut?.value) {
        marketPlanRichText.fields.content.value = this.props.fields.signedOut.value
        showMarketPlanMessage = true;
    } else if (!isRegistered && this.props?.fields?.notRegistered?.value && isLoaded) {
        marketPlanRichText.fields.content.value = this.props.fields.notRegistered.value
        showMarketPlanMessage = true;
    }
    return (
      <div
        className={`imc-gallery imc-gallery--align-flex-start ${gridClass}
                imc-gallery--padding-left imc-vr--collosal`}
      > <div className={'imc-gallery__item imc-exhibitors__panel'}>
          <Placeholder name="imc-category-filter" rendering={rendering} />
        </div>
        <div className="imc-gallery__item">
          <div className="">

            <Placeholder name="imc-typeahead" rendering={rendering} />
            {(showMarketPlanMessage) && <div className={`imc-section imc-section--atmarket-primary imc-section--padded-mediumsmall imc-section--padded-left-medium imc-section--padded-right-medium imc-section--margin-bottom-small`}> 
                <div className={`am-grid imc-gallery imc-gallery--1-2 imc-gallery--grow-1`}>
                    <div className={`imc-gallery__item`}>
                        <RichTextComponent {...marketPlanRichText} rendering={marketPlanRichText} />
                    </div>
                    {(this.props?.fields?.marketPlanLink?.value?.href) && <div className={`imc-gallery__item`}>
                        <Link field={this.props.fields.marketPlanLink} className={`imc-type--color-basic-white`} />
                    </div>}
                </div>
            </div>}
            <Placeholder name="imc-exhibitorad" rendering={rendering} />

          </div>

          {!this.props.sitecoreContext.pageEditing &&
            this.renderResults()
          }
        </div>
      </div >
    );
  }
}

SearchResultsExhibitorsComponent.propTypes = propTypes;
SearchResultsExhibitorsComponent.defaultProps = defaultProps;

function mapStateToProps(state) {
    return {
        planLoaded: (state?.marketPlanReducer?.loaded) ? state.marketPlanReducer.loaded : false,
        isRegistered: (state?.marketPlanReducer?.isRegistered) ? state.marketPlanReducer.isRegistered : false,
    }
}

export default withSitecoreContext()(connect(mapStateToProps)(SearchResultsExhibitorsComponent));
