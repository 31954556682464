import React, { Component } from 'react';
import {withSitecoreRouter} from 'utils/withRouter';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import SearchItemThumb from 'modules/searchItemThumb';
import Pagination from 'modules/search/Pagination.jsx';
import {getExhibitorCatalogURL} from "utils/exhibitor";
/**
 * TabMyLists component
 * todo: create
 * @constructor
 */

class DigitalShowroomCatalogList extends Component {
    /**
     * @method constructor
     * @param {object} props Incoming props
     */
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            showPdf: false,
            data: {},
            showModal: false,
        };
    }

    /**
     * @method constructor
     * @returns {*} Components
     */
    renderCatalogs() {
        const { tabData, sitecoreContext } = this.props;
        const isEditing = sitecoreContext && sitecoreContext.pageEditing;
        if(tabData.catalogs && Array.isArray(tabData.catalogs))
         return tabData.catalogs.map(
            catalog => {
                const { lineDetail, router } = this.props;
                let index = 2;
                let isLine = false;
                if (lineDetail) {
                    index = 4;
                    isLine = true;
                }
                const exhibitId = router.location.pathname.split('/')[index];
                const mainType = isLine ? 'line' : 'exhibitor';
                const catFav = {
                    itemId: exhibitId,
                    itemType: mainType,
                    label: catalog.name,
                    contentName: '',
                    itemContents: [{
                        deleted: true, //Leave this in true for adding and item thru the copy endpoint
                        contentName: catalog.name,
                        itemId: catalog.id,
                        itemType: 'catalog',
                    }],
                };
                return (
                    <SearchItemThumb
                        key={catalog.id}
                        url={getExhibitorCatalogURL(catalog.exhibitorId, catalog.id)}
                        image={isEditing ? '' : catalog.previewImageLink}
                        newWindow={false}
                        actionItem={catFav}
                        name={catalog.name}
                        mainClass="imc-catalog"
                        extraClass={'imc-catalog__item-quarter'}
                        itemID={catalog.id}
                        width={273}
                        height={273}
                        isNew={catalog.newProductForMarket}
                    />
                )
            }
        );
        else return [];
    }

    /**
     * @method render
     * @description Renders the DOM element
     * @returns {*} Rendered component
     */
    render() {
        const { tabData } = this.props;
        const catCount = tabData.catCount;
        const searchTerm = tabData.term;
        const size = this.props.currentTab && this.props.currentTab.size? this.props.currentTab.size.value: 15;
        const filterTermsCount = Object.keys(tabData.filterTerms).length;
        const numberOfPages = Math.ceil(parseInt(catCount, 10) / size);
        if (tabData.catalogs !== undefined && tabData.catCount > 0) {
            return (
                <div>
                    {/* <AlertBox text={`${tabData.catCount} Matching Catalogs${(searchTerm !== '' || filterTermsCount >= 1) ? ', for': ''} ${(filterTermsCount >= 1) ? `${filterTermsCount} Filter${(filterTermsCount > 1) ? 's' : ''}` : ''} ${(searchTerm !== '' && filterTermsCount >= 1) ? 'and': ''} ${searchTerm !== '' ?`“${searchTerm}”` : ''}`} /> */}
                    <div className="imc-vr--large">
                        <Placeholder name='imc-search-bar' rendering={this.props.rendering} />
                    </div>
                    <div className="imc-gallery imc-vr--collosal imc-gallery--justify-left imc-catalog">
                        {this.renderCatalogs()}
                    </div>

                    { <Pagination totalPages={numberOfPages} nonSearch /> }
                </div>
            );
        }

        return (
            <div
                className={`imc-gallery imc-gallery--padding-left imc-gallery--align-flex-start
                imc-vr--xxxlarge imc-gallery--25-75 imc-searchresults`}
            >
                <div className="imc-vr--large">
                    <Placeholder name='imc-search-bar' rendering={this.props.rendering} />
                </div>
                <div><p className="imc-content">There are no catalogs available.</p></div>
            </div>
        );
    }
}

export default withSitecoreContext()(
    withSitecoreRouter(DigitalShowroomCatalogList)
);
